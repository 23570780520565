import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SingleLine from "./Form Content/SingleLine";
import MultiLine from './Form Content/MultiLine';
import Numeric from './Form Content/Numeric';
import SingleLineRightBarContent from './Form Rightbar Content/SingleLineRightBarContent';
import MultiLineRightBarContent from "./Form Rightbar Content/MultiLineRightBarContent";
import NumericRightBarContent from './Form Rightbar Content/NumericRightBarContent';
import Checkbox from "./Form Content/Checkbox";
import CheckboxRightBarContent from "./Form Rightbar Content/CheckboxRightBarContent";
import Dropdowns from "./Form Content/Dropdowns";
import DropdownRightBarContent from "./Form Rightbar Content/DropdownRightBarContent";
import YesNo from "./Form Content/YesNo";
import YesNoRightBarContent from "./Form Rightbar Content/YesNoRightBarContent";
import Date from "./Form Content/Date";
import DateRightBarContent from "./Form Rightbar Content/DateRightBarContent";
import { ConfirmDialog } from "primereact/confirmdialog";
import Email from "./Form Content/Email";
import EmailRightBarContent from "./Form Rightbar Content/EmailRightBarContent";
import PhoneNumber from "./Form Content/PhoneNumber";
import PhoneNumberRightBarContent from "./Form Rightbar Content/PhoneNumberRightBarContent";
import Label from "./Form Content/Label";
import LabelRightBarContent from "./Form Rightbar Content/LabelRightBarContent";
import FileUploads from "./Form Content/FileUploads";
import FileUploadRightBarContent from "./Form Rightbar Content/FileUploadRightBarContent";
import api from "../../api";
import { useLocation } from 'react-router-dom';
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import PreviewPage from "./PreviewPage";

const CreateForms = () => {
  const location = useLocation();
  const toast = useRef(null);
  const searchParams = new URLSearchParams(location.search);
  const projectName = searchParams.get('projectName') || '';
  const projectId = searchParams.get('projectId') || '';

  const [selectedItems, setSelectedItems] = useState([]);
  const [uniqueId, setUniqueId] = useState(1);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [inputSwitchStates, setInputSwitchStates] = useState([]);
  const [inputSwitchStates1, setInputSwitchStates1] = useState([]);
  const [inputSwitchStates2, setInputSwitchStates2] = useState([]);
  const [inputSwitchStates3, setInputSwitchStates3] = useState([]);
  const [dropdownStates, setDropdownStates] = useState([]);
  const [dropdownStates1, setDropdownStates1] = useState([]);
  const [dropdownStates2, setDropdownStates2] = useState([]);
  const [valueStates, setValueStates] = useState([]);
  const [textareaStates, setTextareaStates] = useState([]);
  const [visible, setVisible] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [minValues, setMinValues] = useState({});
  const [maxValues, setMaxValues] = useState({});
  const [minDate, setMinDate] = useState({});
  const [maxDate, setMaxDate] = useState({});
  const [ddlWidgetTypes, setDdlWidgetTypes] = useState({});
  const [ddlWidgetTypeCodes, setDdlWidgetTypeCodes] = useState({});
  const [ddlCheckboxTypes, setDdlCheckboxTypes] = useState({});
  const [ddlCheckboxTypeCodes, setDdlCheckboxTypeCodes] = useState({});
  const [checkboxOptionValues, setCheckboxOptionValues] = useState({});
  const [dropdownOptionValues, setDropdownOptionValues] = useState({})

  const [singleLineTextValues, setSingleLineTextValues] = useState({});
  const [multiLineTextValues, setMultiLineTextValues] = useState({});
  const [numericTextValues, setNumericTextValues] = useState({});
  const [checkboxTextValues, setCheckboxTextValues] = useState({});
  const [dropdownTextValues, setDropdownTextValues] = useState({});
  const [yesNoTextValues, setYesNoTextValues] = useState({});
  const [dateTextValues, setDateTextValues] = useState({});
  const [emailTextValues, setEmailTextValues] = useState({});
  const [phoneNumberTextValues, setPhoneNumberTextValues] = useState({});
  const [labelTextValues, setLabelTextValues] = useState({});
  const [fileUploadTextValues, setFileUploadTextValues] = useState({});

  const [questionTypes, setQuestionTypes] = useState({});


  useEffect(() => {
    const handleBeforeUnload = (event) => {
        const message = "Are you sure you want to refresh?";
        event.returnValue = message;
        return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
}, []);

  const handleSingleLineInputChange = (id, value) => {
    console.log(`Updating text for ID ${id} to: ${value}`);
 
    setSelectedItems((prevItems) =>
    prevItems.map((item) =>
      item.id === id ? { ...item, txtQuestion: value } : item
    )
   );
   setSingleLineTextValues((prevValues) => ({
    ...prevValues,
    [id]: value,
  }));
  };

  const handleMultiLineInputChange = (id, value) => {
    setSelectedItems((prevItems) =>
    prevItems.map((item) =>
      item.id === id ? { ...item, txtMultiQuestion: value } : item
    )
   );
   setMultiLineTextValues((prevValues) => ({
    ...prevValues,  
    [id]: value,
  }));
  };

  const handleNumericInputChange = (id,value) => {
    setSelectedItems((prevItems) =>
    prevItems.map((item) =>
      item.id === id ? { ...item, txtNumericQuestion: value } : item
    )
   );
   setNumericTextValues((prevValues) => ({
    ...prevValues,
    [id]: value, 
   }));
  };

  const handleCheckboxInputChange = (id, value, optionsText) => {
    setSelectedItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, txtCheckboxQuestion: value, OptionsText: optionsText }
          : item
      )
     );
     setCheckboxTextValues((prevValues) => ({
      ...prevValues,
      [id]: value, 
     }));
    };

  const handleDropdownsInputChange = (id, value, optionsText) => {
    setSelectedItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, txtDropdownsQuestion: value, OptionsText: optionsText }
          : item
      )
     );
     setDropdownTextValues((prevValues) => ({
      ...prevValues,
      [id]: value, 
     }));
    };

    const handleYesNoInputChange = (id, value, optionsText) => {
      setSelectedItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id
            ? { ...item, txtYesNoQuestion: value, OptionsText: optionsText }
            : item
        )
       );
       setYesNoTextValues((prevValues) => ({
        ...prevValues,
        [id]: value, 
       }));
      };

      const handleDateInputChange = (id, value) => {
        setSelectedItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, txtDateQuestion: value } : item
        )
       );
       setDateTextValues((prevValues) => ({
        ...prevValues,
        [id]: value, 
       }));
      };

      const handleEmailInputChange = (id, value) => {
        setSelectedItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, txtEmailQuestion: value } : item
        )
       );
       setEmailTextValues((prevValues) => ({
        ...prevValues,
        [id]: value, 
       }));
      };

      const handlePhoneInputChange = (id, value) => {
        setSelectedItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, txtPhoneQuestion: value } : item
        )
       );
       setPhoneNumberTextValues((prevValues) => ({
        ...prevValues,
        [id]: value, 
       }));
      };

      const handleHeadingInputChange = (id, value) => {
        setSelectedItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, txtHeadingQuestion: value } : item
        )
       );
       setLabelTextValues((prevValues) => ({
        ...prevValues,
        [id]: value, 
       }));
      };

      const handleFileUploadInputChange = (id, value) => {
        setSelectedItems((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, txtFileUploadQuestion: value } : item
        )
       );
       setFileUploadTextValues((prevValues) => ({
        ...prevValues,
        [id]: value, 
       }));
      };


  const handleCheckboxOptionsChange = (id, value) => {
    setCheckboxOptionValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
  };

  // const handleDropdownOptionsChange = (id, value) => {
  //   setDropdownOptionValues((prevValues) => ({
  //     ...prevValues,
  //     [id]: value,
  //     // [id]: value.split('$$').map((option) => option.trim()),
  //   }));
  // };

  // const handleDropdownOptionsChange = (id, value) => {
  //   setDropdownOptionValues((prevValues) => {
  //     const splitValues = value.split('$$').map((option) => option.trim());
  
  //     return {
  //       ...prevValues,
  //       [id]: value,
  //       [`${id}_split`]: [...prevValues[`${id}_split`] || [], ...splitValues],
  //     };
  //   });
  // };

  const handleDropdownOptionsChange = (id, value) => {
    setDropdownOptionValues((prevOptions) => ({
      ...prevOptions,
      [id]: value,
      [`${id}_split`]: value.split('$$').map((option) => option.trim()),
    }));
  };
  

  const cardStyle = {
    position: "relative",
    overflow: "auto",
    width: "100%",
    background: "#F2F2F2",
    paddingBottom:"15px"
  };

  const handleItemClick = (item, index) => {
    const newItem = { id: uniqueId, component: item };
    setUniqueId(uniqueId + 1);
    setSelectedItems([...selectedItems, newItem]);
    // setSelectedItems((prevItems) => [...prevItems, newItem]); 
    setQuestionTypes((prevTypes) => ({ ...prevTypes, [uniqueId]: questionTypes }));
    setUniqueId(uniqueId + 1);
    setSelectedCardId(newItem.id);
    setInputSwitchStates((prevStates) => [...prevStates, false]);
    setInputSwitchStates1((prevStates) => [...prevStates, false]);
    setInputSwitchStates2((prevStates) => [...prevStates, false]);
    setInputSwitchStates3((prevStates) => [...prevStates, false]);
    setDropdownStates((prevStates) => [...prevStates, null]);
    setDropdownStates1((prevStates) => [...prevStates, null]);
    setDropdownStates2((prevStates) => [...prevStates, null]);
    setValueStates((prevStates) => [...prevStates, ""]);
    setTextareaStates((prevStates) => [...prevStates, ""]);
    setMinValues({ ...minValues, [newItem.id]: "" });
    setMaxValues({ ...maxValues, [newItem.id]: "" });
    setMinDate({ ...minDate, [newItem.id]: "" });
    setMaxDate({ ...maxDate, [newItem.id]: "" });
    setDdlWidgetTypes({ ...ddlWidgetTypes, [newItem.id]: "" });
    setDdlWidgetTypeCodes({ ...ddlWidgetTypeCodes, [newItem.id]: "" });
    setDdlCheckboxTypes({ ...ddlCheckboxTypes, [newItem.id]: "" });
    setDdlCheckboxTypeCodes({ ...ddlCheckboxTypeCodes, [newItem.id]: "" });

    console.log(`Item with ID ${newItem.id} added.`);
  };

  // useEffect(() => {
  //   // Code that depends on updated state
  //   if (selectedCardId !== null) {
  //     console.log(`Item with ID ${selectedCardId} added.`);
  //   }
  // }, [selectedCardId]);

  const handleDuplicate = (id) => {
    const selectedItem = selectedItems.find((item) => item.id === id);
    if (selectedItem) {
      const newDuplicateItem = {
        id: uniqueId,
        component: React.cloneElement(selectedItem.component, { id: uniqueId, txtQuestion: selectedItem.component.props.txtQuestion }),
      };
  
      console.log("newDuplicateItem", newDuplicateItem);
      setUniqueId(uniqueId + 1);
      setSelectedItems([...selectedItems, newDuplicateItem]);
      setUniqueId(uniqueId + 1);
      setSelectedCardId(newDuplicateItem.id)
      setInputSwitchStates((prevStates) => [...prevStates, false]);
      setInputSwitchStates1((prevStates) => [...prevStates, false]);
      setInputSwitchStates2((prevStates) => [...prevStates, false]);
      setInputSwitchStates3((prevStates) => [...prevStates, false]);
      setDropdownStates((prevStates) => [...prevStates, null]);
      setDropdownStates1((prevStates) => [...prevStates, null]);
      setDropdownStates2((prevStates) => [...prevStates, null]);
      setValueStates((prevStates) => [...prevStates, ""]);
      setTextareaStates((prevStates) => [...prevStates, ""]);
      setMinValues({ ...minValues, [newDuplicateItem.id]: "" });
      setMaxValues({ ...maxValues, [newDuplicateItem.id]: "" });
      setMinDate({ ...minDate, [newDuplicateItem.id]: "" });
      setMaxDate({ ...maxDate, [newDuplicateItem.id]: "" });
      setDdlWidgetTypes({ ...ddlWidgetTypes, [newDuplicateItem.id]: "" });
      setDdlWidgetTypeCodes({ ...ddlWidgetTypeCodes, [newDuplicateItem.id]: "" });
      setDdlCheckboxTypes({ ...ddlCheckboxTypes, [newDuplicateItem.id]: "" });
      setDdlCheckboxTypeCodes({ ...ddlCheckboxTypeCodes, [newDuplicateItem.id]: "" });
    }
  };

  // const handleDelete = (id) => {
  //   const updatedItems = selectedItems.filter((item) => item.id !== id);
  //   setSelectedItems(updatedItems);
  //   setInputSwitchStates((prevStates) =>
  //     prevStates.filter((_, index) => index !== id)
  //   );
  //   setDropdownStates((prevStates) =>
  //     prevStates.filter((_, index) => index !== id)
  //   );
  //   setValueStates((prevStates) =>
  //     prevStates.filter((_, index) => index !== id)
  //   );
  //   setTextareaStates((prevStates) =>
  //     prevStates.filter((_, index) => index !== id)
  //   );
  //   console.log(`Item with ID ${id} deleted.`);
  // };

  const handleDelete = (id) => {
    // Find the index of the item with the given id
    const index = selectedItems.findIndex((item) => item.id === id);
  
    if (index !== -1) {
      // Remove the item from selectedItems
      const updatedItems = [...selectedItems];
      updatedItems.splice(index, 1);
      setSelectedItems(updatedItems);
  
      // Remove data from other state arrays based on the index
      // setInputSwitchStates((prevStates) => prevStates.filter((_, i) => i !== index));
      // setDropdownStates((prevStates) => prevStates.filter((_, i) => i !== index));
      // setValueStates((prevStates) => prevStates.filter((_, i) => i !== index));
      // setTextareaStates((prevStates) => prevStates.filter((_, i) => i !== index));
  
      console.log(`Item with ID ${id} deleted.`);
     }
  };
  
  const handleDiscardAll = () => {
    setSelectedItems([]);
    setInputSwitchStates([]);
    setDropdownStates([]);
    setValueStates([]);
    setTextareaStates([]);
    console.log("All items deleted.");
  };

  const accept = () => { handleDiscardAll() }

  const reject = () => { setVisible(false) }

  const handleCardClick = (id) => {
    setSelectedCardId(id);
  };

  const handleInputChange = (id, e) => {
    setInputSwitchStates((prevStates) =>
      prevStates.map((state, index) => (index === id ? e.target.value : state))
    );
  };
  const handleInputChange1 = (id, e) => {
    setInputSwitchStates1((prevStates) =>
      prevStates.map((state, index) => (index === id ? e.target.value : state))
    );
  };
  const handleInputChange2 = (id, e) => {
    setInputSwitchStates2((prevStates) =>
      prevStates.map((state, index) => (index === id ? e.target.value : state))
    );
  };
  const handleInputChange3 = (id, e) => {
    setInputSwitchStates3((prevStates) =>
      prevStates.map((state, index) => (index === id ? e.target.value : state))
    );
  };

  const handleDropdownChange = (id, e) => {
    setDropdownStates((prevStates) =>
      prevStates.map((state, index) => (index === id ? e.target.value : state))
    );
    const selectedOption = e.value;
    const selectedOptionCode = e.value.code;

    setDdlWidgetTypes({ ...ddlWidgetTypes, [id]: selectedOption.name });
    setDdlWidgetTypeCodes({ ...ddlWidgetTypeCodes, [id]: selectedOptionCode });
  };

  const handleDropdownChange1 = (id, e) => {
    setDropdownStates1((prevStates) =>
      prevStates.map((state, index) => (index === id ? e.target.value : state))
    );
    const selectedOption1 = e.value;
    const selectedOptionCode1 = e.value.code;

    setDdlCheckboxTypes({ ...ddlCheckboxTypes, [id]: selectedOption1.name });
    setDdlCheckboxTypeCodes({ ...ddlCheckboxTypeCodes, [id]: selectedOptionCode1 });
  };

  const handleDropdownChange2 = (id, e) => {
    setDropdownStates2((prevStates) =>
      prevStates.map((state, index) => (index === id ? e.target.value : state))
    );
  };

  const handleValueChange = (id, e) => {
    setValueStates((prevStates) =>
      prevStates.map((state, index) => (index === id ? e.target.value : state))
    );
    setMinValues({ ...minValues, [id]: e.target.value });
  };

  const handleTextareaChange = (id, e) => {
    setTextareaStates((prevStates) =>
      prevStates.map((state, index) => (index === id ? e.target.value : state))
    );
    setMaxValues({ ...maxValues, [id]: e.target.value });
  };

  const formatDate = (date) => {
    if (date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return '';
  };
  const handleMinDateChange = (id, value) => {
    setMinDate({ ...minDate, [id]: value });
  };

  const handleMaxDateChange = (id, value) => {
    setMaxDate({ ...maxDate, [id]: value });
  };


  const singleLineTextContent = (id) => (
    <SingleLineRightBarContent
      id={id}
      inputSwitchState={inputSwitchStates[id]}
      inputSwitchState1={inputSwitchStates1[id]}
      inputSwitchState2={inputSwitchStates2[id]}
      dropdownState={dropdownStates[id]}
      valueState={valueStates[id]}
      textareaState={textareaStates[id]}
      handleInputChange={handleInputChange}
      handleInputChange1={handleInputChange1}
      handleInputChange2={handleInputChange2}
      handleDropdownChange={handleDropdownChange}
      handleValueChange={handleValueChange}
      handleTextareaChange={handleTextareaChange}
      handleDelete={handleDelete}
      handleDuplicate={handleDuplicate}
    />
  );

  const multiLineTextContent = (id) => (
    <MultiLineRightBarContent
      id={id}
      inputSwitchState={inputSwitchStates[id]}
      inputSwitchState1={inputSwitchStates1[id]}
      inputSwitchState2={inputSwitchStates2[id]}
      dropdownState={dropdownStates[id]}
      valueState={valueStates[id]}
      textareaState={textareaStates[id]}
      handleInputChange={handleInputChange}
      handleInputChange1={handleInputChange1}
      handleInputChange2={handleInputChange2}
      handleDropdownChange={handleDropdownChange}
      handleValueChange={handleValueChange}
      handleTextareaChange={handleTextareaChange}
      handleDelete={handleDelete}
      handleDuplicate={handleDuplicate}/>
  );

  const numericContent = (id) => (
    <NumericRightBarContent
    id={id}
    inputSwitchState={inputSwitchStates[id]}
    inputSwitchState1={inputSwitchStates1[id]}
    inputSwitchState2={inputSwitchStates2[id]}
    dropdownState={dropdownStates[id]}
    valueState={valueStates[id]}
    textareaState={textareaStates[id]}
    handleInputChange={handleInputChange}
    handleInputChange1={handleInputChange1}
    handleInputChange2={handleInputChange2}
    handleDropdownChange={handleDropdownChange}
    handleValueChange={handleValueChange}
    handleTextareaChange={handleTextareaChange}
    handleDelete={handleDelete}
    handleDuplicate={handleDuplicate} />
  );

  const checkboxContent = (id) => (
    <CheckboxRightBarContent
    id={id}
    inputSwitchState={inputSwitchStates[id]}
    inputSwitchState3={inputSwitchStates3[id]}
    dropdownState1={dropdownStates1[id]}
    valueState={valueStates[id]}
    textareaState={textareaStates[id]}
    handleInputChange={handleInputChange}
    handleInputChange3={handleInputChange3}
    handleDropdownChange1={handleDropdownChange1}
    handleValueChange={handleValueChange}
    handleTextareaChange={handleTextareaChange}
    handleDelete={handleDelete}
    handleDuplicate={handleDuplicate} />
  );

  const dropdownContent = (id) => (
    <DropdownRightBarContent
    id={id}
    inputSwitchState={inputSwitchStates[id]}
    inputSwitchState3={inputSwitchStates3[id]}
    dropdownState={dropdownStates[id]}
    valueState={valueStates[id]}
    textareaState={textareaStates[id]}
    handleInputChange={handleInputChange}
    handleInputChange3={handleInputChange3}
    handleDropdownChange={handleDropdownChange}
    handleValueChange={handleValueChange}
    handleTextareaChange={handleTextareaChange}
    handleDelete={handleDelete}
    handleDuplicate={handleDuplicate} />
  );

  const yesnoContent = (id) => (
    <YesNoRightBarContent
    id={id}
    inputSwitchState={inputSwitchStates[id]}
    inputSwitchState1={inputSwitchStates1[id]}
    inputSwitchState2={inputSwitchStates2[id]}
    dropdownState={dropdownStates[id]}
    valueState={valueStates[id]}
    textareaState={textareaStates[id]}
    handleInputChange={handleInputChange}
    handleInputChange1={handleInputChange1}
    handleInputChange2={handleInputChange2}
    handleDropdownChange={handleDropdownChange}
    handleValueChange={handleValueChange}
    handleTextareaChange={handleTextareaChange}
    handleDelete={handleDelete}
    handleDuplicate={handleDuplicate} />
  );

  const dateContent = (id) => (
    <DateRightBarContent
    id={id}
    inputSwitchState={inputSwitchStates[id]}
    dropdownState={dropdownStates[id]}
    valueState={valueStates[id]}
    textareaState={textareaStates[id]}
    handleInputChange={handleInputChange}
    handleMinDateChange={handleMinDateChange}
    handleMaxDateChange={handleMaxDateChange}
    handleDelete={handleDelete}
    minDate={minDate}
    maxDate={maxDate}
    handleDuplicate={handleDuplicate}
    />
  );

  const emailContent = (id) => (
    <EmailRightBarContent
    id={id}
    inputSwitchState={inputSwitchStates[id]}
    inputSwitchState1={inputSwitchStates1[id]}
    inputSwitchState2={inputSwitchStates2[id]}
    dropdownState={dropdownStates[id]}
    valueState={valueStates[id]}
    textareaState={textareaStates[id]}
    handleInputChange={handleInputChange}
    handleInputChange1={handleInputChange1}
    handleInputChange2={handleInputChange2}
    handleDropdownChange={handleDropdownChange}
    handleValueChange={handleValueChange}
    handleTextareaChange={handleTextareaChange}
    handleDelete={handleDelete}
    handleDuplicate={handleDuplicate} />
  );

  const phoneNumberContent = (id) => (
    <PhoneNumberRightBarContent
    id={id}
    inputSwitchState={inputSwitchStates[id]}
    inputSwitchState1={inputSwitchStates1[id]}
    inputSwitchState2={inputSwitchStates2[id]}
    dropdownState={dropdownStates[id]}
    valueState={valueStates[id]}
    textareaState={textareaStates[id]}
    handleInputChange={handleInputChange}
    handleInputChange1={handleInputChange1}
    handleInputChange2={handleInputChange2}
    handleDropdownChange={handleDropdownChange}
    handleValueChange={handleValueChange}
    handleTextareaChange={handleTextareaChange}
    handleDelete={handleDelete}
    handleDuplicate={handleDuplicate} />
  );

  const labelContent = (id) => (
    <LabelRightBarContent
    id={id}
    inputSwitchState={inputSwitchStates[id]}
    inputSwitchState1={inputSwitchStates1[id]}
    inputSwitchState2={inputSwitchStates2[id]}
    dropdownState={dropdownStates[id]}
    valueState={valueStates[id]}
    textareaState={textareaStates[id]}
    handleInputChange={handleInputChange}
    handleInputChange1={handleInputChange1}
    handleInputChange2={handleInputChange2}
    handleDropdownChange={handleDropdownChange}
    handleValueChange={handleValueChange}
    handleTextareaChange={handleTextareaChange}
    handleDelete={handleDelete}
    handleDuplicate={handleDuplicate} />
  );

  const fileUploadContent = (id) => (
    <FileUploadRightBarContent
    id={id}
    inputSwitchState={inputSwitchStates[id]}
    inputSwitchState1={inputSwitchStates1[id]}
    inputSwitchState2={inputSwitchStates2[id]}
    dropdownState={dropdownStates[id]}
    valueState={valueStates[id]}
    textareaState={textareaStates[id]}
    handleInputChange={handleInputChange}
    handleInputChange1={handleInputChange1}
    handleInputChange2={handleInputChange2}
    handleDropdownChange={handleDropdownChange}
    handleValueChange={handleValueChange}
    handleTextareaChange={handleTextareaChange}
    handleDelete={handleDelete}
    handleDuplicate={handleDuplicate} />
  );

    // const handlePreviewClick = () => {
    //   const previewPageUrl = '/preview';
    //   window.open(previewPageUrl, '_blank');
    // };
    const [visible1, setVisible1] = useState(false);

    const handlePreviewClick = () => {
      if (selectedItems.length === 0) {
        showCustomToast("Please add items to preview.");
      } else {
        setVisible1(true);
      }
    };
  
    const showCustomToast = (message) => {
      toast.current.show({ severity: 'info', summary: 'Info', detail: message });
    }; 
  
    const onHide1 = () => {
      setVisible1(false);
    };


  const handleClick = async () => {
    try {
      const hasEmptySingleLine = selectedItems.some(
        (item) =>
          item.component.type === SingleLine &&
          !singleLineTextValues[item.id]
      );
      const hasEmptyMultiLine = selectedItems.some(
        (item) =>
          item.component.type === MultiLine &&
          !multiLineTextValues[item.id]
      );
      const hasEmptyNumeric = selectedItems.some(
        (item) =>
          item.component.type === Numeric &&
          !numericTextValues[item.id]
      );
      const hasEmptyCheckbox = selectedItems.some(
        (item) =>
          item.component.type === Checkbox &&
          !checkboxTextValues[item.id]
      );
      const hasEmptyDropdown = selectedItems.some(
        (item) =>
          item.component.type === Dropdowns &&
          !dropdownTextValues[item.id]
      );
      const hasEmptyYesNo = selectedItems.some(
        (item) =>
          item.component.type === YesNo &&
          !yesNoTextValues[item.id]
      );
      const hasEmptyDate = selectedItems.some(
        (item) =>
          item.component.type === Date &&
          !dateTextValues[item.id]
      );
      const hasEmptyEmail = selectedItems.some(
        (item) =>
          item.component.type === Email &&
          !emailTextValues[item.id]
      );
      const hasEmptyPhoneNumber = selectedItems.some(
        (item) =>
          item.component.type === PhoneNumber &&
          !phoneNumberTextValues[item.id]
      );
      const hasEmptyLabel = selectedItems.some(
        (item) =>
          item.component.type === Label &&
          !labelTextValues[item.id]
      );
      const hasEmptyfileUpload = selectedItems.some(
        (item) =>
          item.component.type === FileUploads &&
          !fileUploadTextValues[item.id]
      );
  
      if (selectedItems.length === 0 || formTitle.trim() === '' ||
       hasEmptySingleLine ||
       hasEmptyMultiLine ||
       hasEmptyNumeric ||
       hasEmptyCheckbox ||
       hasEmptyDropdown ||
       hasEmptyYesNo ||
       hasEmptyDate ||
       hasEmptyEmail ||
       hasEmptyPhoneNumber ||
       hasEmptyLabel ||
       hasEmptyfileUpload
       ) {
        showToast("Please select an item, enter form name, and fill in all questions fileds.");
        return;
      }
      const staticData = {
        "0": {
          "txtProjectId": projectId,
          "txtFormId": "",  //empty
          "txtFormName": formTitle,
          "txtFormSecId": "MQ==",
          "project_name": projectName,
          "ddlsurveyType": "3",
          "ddlsurveyTypeName": "Both Mobile And Web",
          "ddldisplayType": "1",
          "ddldisplayTypeName": "Form Type"
        },
      };

      const dynamicData = Object.fromEntries(
        // selectedItems.map((item) => [
        //   item.id,
        selectedItems.map((item, index) => [
          index + 1,
          {
            "chkIsMandatory": inputSwitchStates[item.id] ? "1" : "0",
            "ddlinputType": item.component.type === SingleLine ? "1" :
                            (item.component.type === MultiLine ? "2" :
                            (item.component.type === Numeric ? "3" :
                            (item.component.type === Checkbox ? "4" :
                            (item.component.type === Dropdowns ? "5" :
                            (item.component.type === YesNo ? "6" :
                            (item.component.type === Date ? "7" :
                            (item.component.type === Email ? "10" :
                            (item.component.type === PhoneNumber ? "11" :
                            (item.component.type === Label ? "12" :
                            (item.component.type === FileUploads ? "14" : "")))))))))),

            "ddlinputTypeName": item.component.type === SingleLine ? "Text Box" :
                                (item.component.type === MultiLine ? "Comments" :
                                (item.component.type === Numeric ? "Numeric" :
                                (item.component.type === Checkbox ? "Check Box" :
                                (item.component.type === Dropdowns ? "Dropdown" :
                                (item.component.type === YesNo ? "Multiple Choice(radio)" :
                                (item.component.type === Date ? "Date" :
                                (item.component.type === Email ? "Email" :
                                (item.component.type === PhoneNumber ? "Contact" :
                                (item.component.type === Label ? "Heading" :
                                (item.component.type === FileUploads ? "File" : "")))))))))),

            "ddlwidgettype":  item.component.type === SingleLine ? ddlWidgetTypes[item.id] :
                              (item.component.type === MultiLine ? ddlWidgetTypes[item.id] :
                              (item.component.type === Numeric ? "Numeric" :
                              (item.component.type === Date ? "Date" :
                              (item.component.type === Email ? "E-mail" :
                              (item.component.type === PhoneNumber ? "Numeric" :
                              (item.component.type === Label ? ddlWidgetTypes[item.id] : "")))))),

            "ddlwidgettypecode": ddlWidgetTypeCodes[item.id],
            "txtquestion": item.component.type === SingleLine ? item.txtQuestion :
                           (item.component.type === MultiLine ? item.txtMultiQuestion :
                           (item.component.type === Numeric ? item.txtNumericQuestion :
                           (item.component.type === Checkbox ? item.txtCheckboxQuestion :
                           (item.component.type === Dropdowns ? item.txtDropdownsQuestion :
                           (item.component.type === YesNo ? item.txtYesNoQuestion :
                           (item.component.type === Date ? item.txtDateQuestion :
                           (item.component.type === Email ? item.txtEmailQuestion :
                           (item.component.type === PhoneNumber ? item.txtPhoneQuestion :
                           (item.component.type === Label ? item.txtHeadingQuestion :
                           (item.component.type === FileUploads ? item.txtFileUploadQuestion : "")))))))))),

            "txtminlength": item.component.type === SingleLine ? "1" :
                            (item.component.type === MultiLine ? "1" :
                            (item.component.type === Numeric ? "1" :
                            (item.component.type === Email ? "1" :
                            (item.component.type === PhoneNumber ? "10" :
                            (item.component.type === Label ? "1" :
                            (item.component.type === Date ? "10" : "")))))),

            "txtmaxlength": item.component.type === SingleLine ? "250" :
                            (item.component.type === MultiLine ? "250" :
                            (item.component.type === Numeric ? "250" :
                            (item.component.type === Email ? "250" :
                            (item.component.type === PhoneNumber ? "10" :
                            (item.component.type === Label ? "250" :
                            (item.component.type === Date ? "10" : "")))))),

            "txtminValue": minValues[item.id],
            "txtmaxValue": maxValues[item.id],
            "MinDateValue": item.component.type === Date ? formatDate(minDate[item.id]) || '01/01/1900':"",
            "MaxDateValue": item.component.type === Date ? formatDate(maxDate[item.id]) || `31/12/2123`:"",
            "MinYearValue": "",
            "MaxYearValue": "",
            "identifierData": inputSwitchStates1[item.id] ? "1" : "0",
            "useasTitle": inputSwitchStates2[item.id] ? "1" : "0",
            "others_specify":inputSwitchStates3[item.id] ? "1" : "0",
            "selectionType": ddlCheckboxTypes[item.id],
            "selectionTypeId": ddlCheckboxTypeCodes[item.id],
            "dateFormat": "dd/mm/yy",
            "dateseparator": "/",
            "OptionsText": (item.component.type === Checkbox ? checkboxOptionValues[item.id] || "" :
                           (item.component.type === Dropdowns ? dropdownOptionValues[item.id] || "" :
                           (item.component.type === YesNo ? "Yes$$No" || "" : "")))
          },
        ])
      );

       await api.create_Forms(staticData, dynamicData);

      console.log('API call successful');
      toast.current.show({
        severity: "success",
        summary: "Form Created Successful",
        detail: "You are now Created Form",
    });
    setSelectedItems([]);
    setInputSwitchStates([]);
    setDropdownStates([]);
    setValueStates([]);
    setTextareaStates([]);
    setFormTitle("")
    // navigate(-1)
    } catch (error) {
      console.error('API call error:', error.message);
      toast.current.show({
        severity: "error",
        summary: "Form Create Failed",
        detail: "Form Name Already Existed",
    });
    }
  };
  const showToast = (message) => {
    toast.current.show({ severity: 'error', summary: 'Info', detail: message });
  };
  const navigate = useNavigate();


  return (
    <>
    <Toast ref={toast} position="top-right" />
      <div
        className="flex justify-content-between align-items-center"
        style={{ background: "#11409E", width:"100%", position:"fixed" }}
      >
           <Link
                to="#"
                className="flex justify-content-between align-items-center"
                style={{ color: "white", textDecoration: "none" }}
                onClick={() => navigate(-1)}
            >
                <i
                    className="pi pi-angle-left ml-3"
                    style={{ fontSize: "1.5rem" }}
                ></i>
                back
            </Link>
        <h1 style={{ fontSize: "25px", color: "white" }}>{projectName}</h1>
        <div className="flex align-items-center">
        <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Are you sure you want to clear all question ?"
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} />

          <Button className="formcreatebutton" onClick={() => setVisible(true)}>
            <i className="pi pi-times mr-2"></i> Discard All
          </Button>
          <Button className="formcreatebutton ml-2" onClick={handlePreviewClick}>
            <i className="pi pi-eye mr-2"></i> Preview
          </Button>
          <Dialog className="dialogsize" visible={visible1} onHide={onHide1}>
        <PreviewPage
                  selectedItems={selectedItems}
                  singleLineTextValues={singleLineTextValues}
                  multiLineTextValues = {multiLineTextValues}
                  numericTextValues = {numericTextValues}
                  checkboxTextValues = {checkboxTextValues}
                  dropdownTextValues = {dropdownTextValues}
                  yesNoTextValues = {yesNoTextValues}
                  dateTextValues = {dateTextValues}
                  emailTextValues = {emailTextValues}
                  phoneNumberTextValues = {phoneNumberTextValues}
                  labelTextValues = {labelTextValues}
                  inputSwitchStates={inputSwitchStates} 
                  checkboxOptions={checkboxOptionValues}
                  onCheckboxInputChange={handleCheckboxInputChange}
                  onCheckboxOptionsChange={handleCheckboxOptionsChange}
                  dropdownOptions={dropdownOptionValues}
                  formTitle={formTitle}
                  onHide1={onHide1}
                  setCheckboxTextValues={setCheckboxTextValues}
                  />
      </Dialog>
          <Button className="formcreatebutton ml-2 mr-2" onClick={handleClick}>
            <i className="pi pi-save mr-2"></i> Save
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", overflow: "hidden", height: "100vh", paddingTop:"64px" }}>
        <div
          style={{
            width: "400px",
            boxShadow: "6px 0px 6px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#444444",
              margin: "20px 0px 15px 0px",
              fontSize: "14px",
            }}
          >
            Click To Insert the Questions
          </div>
          <Accordion
            activeIndex={selectedItems.map((item, index) => index)}
            onTabChange={(e) => {
              const selectedIndex = e.index;
              handleItemClick(selectedItems[selectedIndex], selectedIndex);
            }}
            style={{
              background: "white",
              overflow: "auto",
              height: "calc(100vh - 60px)",
            }}
          >
            <AccordionTab
              header={
                <div className="flex align-items-center accFont"  >
                  <img src="Group 318.png" alt="logo" className=" mr-2" style={{width:"22px"}}/>
                  Single Line Text
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<SingleLine id={uniqueId} onInputChange={handleSingleLineInputChange} txtQuestion={singleLineTextValues[id]}/>, uniqueId)}
            />

            <AccordionTab
              header={
                <div className="flex align-items-center accFont"  >
                 <img src="Group 237719.png" alt="logo" className="mr-2" style={{width:"20px"}}/>
                  Multi-Line Text
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<MultiLine id={uniqueId} onInputChange={handleMultiLineInputChange} txtQuestion={multiLineTextValues[id]}/>, uniqueId)}
            />
            <AccordionTab
              header={
                <div className="flex align-items-center accFont"  >
                  <img src="Vector.png" alt="logo" className=" mr-2" style={{width:"30px"}}/>
                  Numeric
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<Numeric id={uniqueId} onInputChange={handleNumericInputChange} txtQuestion={numericTextValues[id]}/>, uniqueId)}
            />
            <AccordionTab
              header={
                <div className="flex align-items-center accFont"  >
                  <img src="select_check_box.png" alt="logo" className=" mr-2" style={{width:"27px"}}/>
                  Checkbox
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<Checkbox id={uniqueId} onInputChange={handleCheckboxInputChange} onOptionsChange={handleCheckboxOptionsChange} txtQuestion={checkboxTextValues[id]}/>, uniqueId)}
            />
            <AccordionTab
              header={
                <div className="flex align-items-center accFont"  >
                  <img src="Group 310.png" alt="logo" className=" mr-2" style={{width:"27px"}}/>
                  Dropdown
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<Dropdowns id={uniqueId} onInputChange={handleDropdownsInputChange} onOptionsChange={handleDropdownOptionsChange} txtQuestion={dropdownTextValues[id]}/>, uniqueId)}
            />
            <AccordionTab
              header={
                <div className="flex align-items-center accFont" >
                  <img src="Group 295.png" alt="logo" className=" mr-2" style={{width:"30px"}}/>
                  Yes/No
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<YesNo id={uniqueId} onInputChange={handleYesNoInputChange} txtQuestion={yesNoTextValues[id]}/>, uniqueId)}
            />
            <AccordionTab
              header={
                <div className="flex align-items-center accFont"  >
                  <img src="Group 297.png" alt="logo" className=" mr-2" style={{width:"26px"}}/>
                  Date
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<Date  id={uniqueId} onInputChange={handleDateInputChange} txtQuestion={dateTextValues[id]}/>, uniqueId)}
            />
            <AccordionTab
              header={
                <div className="flex align-items-center accFont"  >
                  <img src="Vectoremail.png" alt="logo" className=" mr-2" style={{width:"23px"}}/>
                  Email
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<Email id={uniqueId} onInputChange={handleEmailInputChange} txtQuestion={emailTextValues[id]}/>, uniqueId)}
            />
            <AccordionTab
              header={
                <div className="flex align-items-center accFont"  >
                  <img src="Vectorphone.png" alt="logo" className=" mr-2" style={{width:"26px"}}/>
                  Phone Number
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<PhoneNumber id={uniqueId} onInputChange={handlePhoneInputChange} txtQuestion={phoneNumberTextValues[id]}/>, uniqueId)}
            />
            <AccordionTab
              header={
                <div className="flex align-items-center accFont"  >
                  <img src="Group 278.png" alt="logo" className=" mr-2" style={{width:"28px"}}/>
                  Label
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<Label id={uniqueId} onInputChange={handleHeadingInputChange} txtQuestion={labelTextValues[id]}/>, uniqueId)}
            />
            {/* <AccordionTab
              header={
                <div className="flex align-items-center accFont"  >
                  <img src="Vectorfile.png" alt="logo" className=" mr-2" style={{width:"30px"}}/>
                  File Upload
                </div>
              }
              headerStyle={{ cursor: "pointer", paddingRight: "0" }}
              onClick={(id) => handleItemClick(<FileUploads id={uniqueId} onInputChange={handleFileUploadInputChange} txtQuestion={fileUploadTextValues[id]}/>, uniqueId)}
            /> */}
          </Accordion>
        </div>

        <div style={cardStyle}>
          <div className="flex justify-content-center ">
            <InputText
              placeholder="Form Title..."
              value={formTitle}
              onChange={(e) => setFormTitle(e.target.value)}
              style={{
                background: "#F2F2F2",
                border: "none",
                fontSize: "28px",
                fontWeight: "700",
                width: "95%",
              }}
            />
          </div>
          {selectedItems.map((item) => (
            <div key={item.id}>
              <div onClick={() => handleCardClick(item.id)}>
                {item.component}
              </div>
            </div>
          ))}

{/* {selectedItems.map((item) => (
  <div key={item.id}>
    <div onClick={() => handleCardClick(item.id)}>
      {item.component.type.name === 'SingleLine' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handleSingleLineInputChange,
          txtQuestion: singleLineTextValues[item.id],
        })
      )}

      {item.component.type.name === 'MultiLine' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handleMultiLineInputChange,
          txtQuestion: multiLineTextValues[item.id],
        })
      )}

      {item.component.type.name === 'Numeric' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handleNumericInputChange,
          txtQuestion: numericTextValues[item.id],
        })
      )}
      {item.component.type.name === 'Checkbox' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handleCheckboxInputChange,
          txtQuestion: checkboxTextValues[item.id],
        })
      )}

     {item.component.type.name === 'Dropdowns' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handleDropdownsInputChange,
          txtQuestion: dropdownTextValues[item.id],
        })
      )}

{item.component.type.name === 'YesNo' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handleYesNoInputChange,
          txtQuestion: yesNoTextValues[item.id],
        })
      )}

{item.component.type.name === 'Date' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handleDateInputChange,
          txtQuestion: dateTextValues[item.id],
        })
      )}

{item.component.type.name === 'Email' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handleEmailInputChange,
          txtQuestion: emailTextValues[item.id],
        })
      )}

{item.component.type.name === 'PhoneNumber' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handlePhoneInputChange,
          txtQuestion: phoneNumberTextValues[item.id],
        })
      )}

{item.component.type.name === 'Label' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handleHeadingInputChange,
          txtQuestion: labelTextValues[item.id],
        })
      )}

{item.component.type.name === 'FileUploads' && (
        React.cloneElement(item.component, {
          id: item.id,
          onInputChange: handleFileUploadInputChange,
          txtQuestion: fileUploadTextValues[item.id],
        })
      )}


    </div>
  </div>
))} */}

{/* {selectedItems.map((item) => (
    <div key={item.id}>
      <div onClick={() => handleCardClick(item.id)}>
        {item.component.type.name === 'SingleLine' && (
          <SingleLine
            id={item.id}
            onInputChange={handleSingleLineInputChange}
            txtQuestion={singleLineTextValues[item.id]}
          />
        )}

        {item.component.type.name === 'MultiLine' && (
          <MultiLine
            id={item.id}
            onInputChange={handleMultiLineInputChange}
            txtQuestion={multiLineTextValues[item.id]}
          />
        )}

        {item.component.type.name === 'Numeric' && (
          <Numeric
            id={item.id}
            onInputChange={handleNumericInputChange}
            txtQuestion={numericTextValues[item.id]}
          />
        )}

        {item.component.type.name === 'Checkbox' && (
          <Checkbox
            id={item.id}
            onInputChange={handleCheckboxInputChange}
            txtQuestion={checkboxTextValues[item.id]}
          />
        )}

        {item.component.type.name === 'Dropdowns' && (
          <Dropdowns
            id={item.id}
            onInputChange={handleDropdownsInputChange}
            txtQuestion={dropdownTextValues[item.id]}
          />
        )}

        {item.component.type.name === 'YesNo' && (
          <YesNo
            id={item.id}
            onInputChange={handleYesNoInputChange}
            txtQuestion={yesNoTextValues[item.id]}
          />
        )}

        {item.component.type.name === 'Date' && (
          <Date
            id={item.id}
            onInputChange={handleEmailInputChange}
            txtQuestion={emailTextValues[item.id]}
          />
        )}

        {item.component.type.name === 'Email' && (
          <Email
            id={item.id}
            onInputChange={handleEmailInputChange}
            txtQuestion={emailTextValues[item.id]}
          />
        )}

        {item.component.type.name === 'PhoneNumber' && (
          <PhoneNumber
            id={item.id}
            onInputChange={handlePhoneInputChange}
            txtQuestion={phoneNumberTextValues[item.id]}
          />
        )}

       {item.component.type.name === 'Label' && (
          <Dropdowns
            id={item.id}
            onInputChange={handleHeadingInputChange}
            txtQuestion={labelTextValues[item.id]}
          />
        )}

      </div>
    </div>
  ))} */}

        </div>

        <div
          className="rightBarContent"
          style={{
            width: "600px",
            boxShadow: "6px 0px 6px rgba(0, 0, 0, 0.1)",
            background: "white",
            overflow:"auto"
          }}
        >
          {selectedCardId !== null && (
            <div>
              {selectedItems.map((item) => {
                if (item.id === selectedCardId) {
                  if (item.component.type === SingleLine) {
                    return singleLineTextContent(item.id);
                  } else if (item.component.type === MultiLine) {
                    return multiLineTextContent(item.id);
                  } else if (item.component.type === Numeric) {
                    return numericContent(item.id);
                  } else if (item.component.type === Checkbox) {
                    return checkboxContent(item.id);
                  } else if (item.component.type === Dropdowns) {
                    return dropdownContent(item.id);
                  } else if (item.component.type === YesNo) {
                    return yesnoContent(item.id);
                  } else if (item.component.type === Date) {
                    return dateContent(item.id);
                  } else if (item.component.type === Email) {
                    return emailContent(item.id);
                  } else if (item.component.type === PhoneNumber) {
                    return phoneNumberContent(item.id);
                  } else if (item.component.type === Label) {
                    return labelContent(item.id);
                  } else if (item.component.type === FileUploads) {
                    return fileUploadContent(item.id);
                  }
                }
                return null;
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateForms;
