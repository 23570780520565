import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Calendar } from "primereact/calendar";
import { BreadCrumb } from "primereact/breadcrumb";
import Header from "../../../Layout/Header";
import { Button } from "primereact/button";
import api from "../../../api";
import { Toast } from "primereact/toast";

const FormDetails = () => {
  const toast = useRef(null);

  const { formName } = useParams();
  const fldNameOfTeamMember = sessionStorage.getItem('fldNameOfTeamMember');
  const navigate = useNavigate();
  const items = [{ label: formName }];
  const home = {
    label: "Home",
    command: () => {
      navigate("/projects");
    },
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
        const message = "Are you sure you want to refresh?";
        event.returnValue = message;
        return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
}, []);

  const [dropdownValues, setDropdownValues] = useState({});

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedRadio, setSelectedRadio] = useState({});


  const handleCheckboxChange = (questionId, option) => {
    setSelectedCheckboxes((prevSelected) => ({
      ...prevSelected,
      [questionId]: {
        ...(prevSelected[questionId] || {}),
        [option]: !prevSelected[questionId]?.[option],
      },
    }));
  };
  
  const handleRadioChange = (questionId, value) => {
    setSelectedRadio((prevSelected) => ({
      ...prevSelected,
      [questionId]: value,
    }));
  
    setSelectedCheckboxes((prevSelected) => ({
      ...prevSelected,
      [questionId]: {},
    }));
  };


  const [selectedRadioButton, setSelectedRadioButton] = useState({});
  const handleRadioButtonChange = (questionId, selectedValue) => {
    setSelectedRadioButton((prevSelected) => ({
      ...prevSelected,
      [questionId]: selectedValue,
    }));
  };
  

  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (selectedDate) => {
    setSelectedDate(selectedDate);
  };

  const [formData, setFormData] = useState([]);
  const handleInputChange = (index, value) => {
    setFormData((prevFormData) => {
      const newFormData = [...prevFormData];
      newFormData[index] = value;
        return newFormData;
    });
  };

  // const handleDropdownChange = (questionId, selectedValue) => {
  //   setDropdownValues((prevValues) => ({
  //     ...prevValues,
  //     [questionId]: selectedValue,
  //   }));
  // };

  const handleDropdownChange = (questionId, selectedValue) => {
    setDropdownValues((prevValues) => ({
      ...prevValues,
      [questionId]: selectedValue,
    }));
  
    setFormData((prevFormData) => {
      const newFormData = [...prevFormData];
      newFormData[questionId] = selectedValue;
      return newFormData;
    });
  };

  const urlParams = new URLSearchParams(window.location.search);
  const encodedQuestions = urlParams.get('questions');
  const questions = JSON.parse(decodeURIComponent(encodedQuestions)) || [];
  console.log("Received Questions:", questions);

  const renderQuestionInput = (question, index) => {
    const isMandatory = question.fld_qn_mandatory === "1";
    const { fld_widget_input_code } = question;
    console.log(question.fld_qn_db_col_name)

    switch (question.fld_qn_widget_type_id) {
      case "1":
        return (
          <div className="mt-4">
          <label>
          {index + 1}. {question.fld_question}
            {isMandatory && <span style={{ color: "red" }}> *</span>}
          </label> <br></br>
          <div className="flex justify-content-center">
            <InputText className="mt-1 userForm"
            value={formData[index] || ''}
              maxLength={question.fld_max_value}
              type="text"
              placeholder={question.fld_question}
              onChange={(e) => handleInputChange(index, e.target.value)}

            />
            </div>
          </div>
        );
      case "2":
        return (
          <div className="mt-4">
          <label>
          {index + 1}. {question.fld_question}
            {isMandatory && <span style={{ color: "red" }}> *</span>}
          </label> <br></br>
          <div className="flex justify-content-center">
           <InputTextarea className="mt-1 userForm"
           value={formData[index] || ''}
              maxLength={question.fld_max_value}
              type="text"
              placeholder={question.fld_question}
              onChange={(e) => handleInputChange(index, e.target.value)}

            />
            </div>
          </div>
        );
      case "3":
        return (
          <div className="mt-4">
          <label>
          {index + 1}. {question.fld_question}
            {isMandatory && <span style={{ color: "red" }}> *</span>}
          </label> <br></br>
          <div className="flex justify-content-center">
            <InputText className="mt-1 userForm"
            value={formData[index] || ''}
              keyfilter="int"
              maxLength={question.fld_max_value}
              type="text"
              placeholder={question.fld_question}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
            </div>
          </div>
        );
      case "4":
        const optionsCheckbox = question.fld_options
          .split("$$")
          .map((option) => option.trim());
        const selectionType = parseInt(question.fld_selection_type_id, 10);

        return (
          <div className="mt-4">
          <label>
          {index + 1}. {question.fld_question}
            {isMandatory && <span style={{ color: "red" }}> *</span>}
          </label> <br></br>

            {/* {optionsCheckbox.map((option) => (
              <div key={option}>
                {selectionType === 1 ? (
                  <div className="mt-1" style={{ display: "flex", alignItems: "center" }}>
                    <RadioButton
                      className="ml-3 mr-2"
                      inputId={option}
                      name={question.fld_qid}
                      value={option}
                      onChange={() => handleRadioChange(question.fld_qid, option)}
                      checked={selectedRadio[question.fld_qid] === option}
                    />
                    <label htmlFor={option}>{option}</label>
                  </div>
                ) : (
                  <div className=" mt-1" style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      className=" ml-3 mr-2"
                      inputId={option}
                      value={option}
                      onChange={() => handleCheckboxChange(question.fld_qid, option)}
                      checked={selectedCheckboxes[question.fld_qid]?.[option]}
                    />
                    <label htmlFor={option}>{option}</label>
                  </div>
                )}
               </div>
            ))} */}
            {optionsCheckbox.map((option) => (
  <div key={option}>
    {selectionType === 1 ? (
      <div className="mt-1" style={{ display: "flex", alignItems: "center" }}>
        <RadioButton
          className="ml-3 mr-2"
          inputId={option}
          name={question.fld_qid}
          value={option}
          onChange={() => handleRadioChange(question.fld_qid, option)}
          checked={selectedRadio[question.fld_qid] === option}
        />
        <label htmlFor={option}>{option}</label>
      </div>
    ) : (
      <div className=" mt-1" style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          className=" ml-3 mr-2"
          inputId={option}
          value={option}
          onChange={() => handleCheckboxChange(question.fld_qid, option)}
          checked={selectedCheckboxes[question.fld_qid]?.[option]}
        />
        <label htmlFor={option}>{option}</label>
      </div>
    )}
  </div>
))}
          </div>
        );
      case "5":
        const options = question.fld_options
          .split("$$")
          .map((option) => ({ label: option, value: option }));
        const selectedValue = dropdownValues[question.fld_qid] || "";
        return (
          <div className="mt-4">
            <label>
            {index + 1}. {question.fld_question}
              {isMandatory && <span  style={{ color: "red" }}>*</span>}
            </label><br></br>
            <div className="flex justify-content-center">
             <Dropdown
              className="mt-1 userForm"
              placeholder={question.fld_question}
              options={options}
              optionLabel="label"
              optionValue="value"
              value={selectedValue}
              onChange={(e) => handleDropdownChange(question.fld_qid, e.value)}
            />
            </div>
          </div>
        );
        case "6":
        const optionsRadio = question.fld_options
          .split("$$")
          .map((option) => ({ label: option.trim(), value: option.trim() }));
        return (
          <div className="mt-4">
            <label>
              {index + 1}. {question.fld_question}
              {isMandatory && <span style={{ color: "red" }}> *</span>}
            </label>
            <br />
            {optionsRadio.map((option) => (
              <div key={option.value} className=" mt-1" style={{ display: "flex", alignItems: "center" }}>
                <RadioButton
                  className=" ml-3 mr-2"
                  inputId={option.value}
                  name={question.fld_qid}
                  value={option.value}
                  onChange={() => handleRadioButtonChange(question.fld_qid, option.value)}
                  checked={selectedRadioButton[question.fld_qid] === option.value}
                />
                <label htmlFor={option.value}>{option.label}</label>
              </div>
            ))}
          </div>
        );
  
      case "7":
        const dateFormat = question.fld_date_format || "mm/dd/yy";
        const minDate = question.fld_min_date
          ? new Date(question.fld_min_date)
          : null;
        const maxDate = question.fld_max_date
          ? new Date(question.fld_max_date)
          : null;

        return (
          <div className="mt-4">
          <label>
          {index + 1}. {question.fld_question}
            {isMandatory && <span style={{ color: "red" }}> *</span>}
          </label> <br></br>
          <div className="flex justify-content-center">
            <Calendar
              className="mt-1 userForm"
              value={selectedDate}
              onChange={(e) => handleDateChange(e.value)}
              showIcon
              dateFormat={dateFormat}
              minDate={minDate}
              maxDate={maxDate}
              placeholder={question.fld_question}
            />
            </div>
          </div>
        );
      case "10":
        return (
          <div className="mt-4">
          <label>
          {index + 1}. {question.fld_question}
            {isMandatory && <span style={{ color: "red" }}> *</span>}
          </label> <br></br>
          <div className="flex justify-content-center">
            <InputText className="mt-1 userForm"
             type="email" placeholder={question.fld_question} 
             onChange={(e) => handleInputChange(index, e.target.value)}
              />
              </div>
          </div>
        );
      case "11":
        return (
          <div className="mt-4">
          <label>
          {index + 1}. {question.fld_question}
            {isMandatory && <span style={{ color: "red" }}> *</span>}
          </label> <br></br>
          <div className="flex justify-content-center">
            <InputText className="mt-1 userForm"
              maxLength={question.fld_max_length}
              keyfilter="int" placeholder={question.fld_question} 
              onChange={(e) => handleInputChange(index, e.target.value)}
             />
            </div>
          </div>
        );
      case "12":
        const headingLevel = parseInt(fld_widget_input_code.substring(1));
        const HeadingTag = `h${
          headingLevel > 0 && headingLevel <= 6 ? headingLevel : 1
        }`;
        return React.createElement(HeadingTag, null, question.fld_question);
      default:
        return null;
    }
  };


  const resetForm = () => {
    setFormData([]);
    setDropdownValues({});
    setSelectedRadio({});
    setSelectedRadioButton({});
    setSelectedCheckboxes({});
    setSelectedDate(null);
  };

  
  const handleButtonClick = async (event, question) => {
    event.preventDefault();

    const missingFields = [];
    questions.forEach((q, index) => {
      if (q.fld_qn_mandatory === "1") {
        let value;
  
        if (q.fld_qn_widget_type_id === "5") {
          value = dropdownValues[q.fld_qid];
        } else if (q.fld_qn_widget_type_id === "4") {
          value = selectedRadio[q.fld_qid];
        } else if (q.fld_qn_widget_type_id === "6") {
          value = selectedRadioButton[q.fld_qid];
        } else if (q.fld_qn_widget_type_id === "7") {
          value = selectedDate;
        } else {
          value = formData[index];
        }
  
        if (!value || (Array.isArray(value) && value.length === 0)) {
          missingFields.push(q.fld_question); // Add the question text to missingFields
        }
      }
    });
  
    if (missingFields.length > 0) {
      toast.current.show({
        severity: "warn",
        summary: "Missing Fields",
        detail: `Please fill the mandatory fields: ${missingFields.join(", ")}`,
      });
      return; // Stop form submission
    }

    try {

const formDataPayload = questions.reduce((acc, question, index) => {
  let value;

  if (question.fld_qn_widget_type_id === "5") {
    value = dropdownValues[question.fld_qid];
  } else if (question.fld_qn_widget_type_id === "4") {
    value = selectedRadio[question.fld_qid];
  } else if (question.fld_qn_widget_type_id === "6") {
    value = selectedRadioButton[question.fld_qid];
  } else if (question.fld_qn_widget_type_id === "7") {
   value = selectedDate ? selectedDate.toLocaleDateString("en-GB") : null;
  } else if (question.fld_qn_widget_type_id === "12") {
    value = question.fld_question;
  } else {
    value = formData[index];
  }


  acc[question.fld_qn_db_col_name] = value;
  return acc;
}, {});



      const question = questions[0];
      const tableName = question.fld_table_name;
      const payload = {
        [tableName]: [
          {
            fld_slno: "1",
            fld_rn: "",
            fld_rf_id: "-99",
            fld_form_start_time: "2023-01-03 10:53:00",
            fld_form_end_time: "2023-01-03 10:55:34",
            fld_is_active: "1",
			      fld_sent_to_server: "1",
			      fld_qid: "",
			      fld_system_inserted_datetime: "2023-01-03 10:55:34",
			      fld_loggedin_user_id: "mobileuser",
			      fld_is_deleted: "",
			      fld_is_deleted_datetime: "",
			      fld_reason_for_deletion: "",
			      fld_modified_no: "",
			      fld_modified_datetime: "",
			      fld_web_version: "W",
			      fld_db_version: "",
            ...formDataPayload,

          }
        ],
        synceddatetime: "2023-04-13 18:17:10",
        formcode: "213",
        appversion: "1.0.0",
        apikey: "kavin",
        apptypeno: "1"
      };

      const syncDataResponse = await api.syncData(payload);
      toast.current.show({
        severity: "success",
        summary: "Form Filled Successful",
        detail: syncDataResponse.responsemessage || "Operation successful.",
      });
      resetForm();
      // navigate("/projects")
      console.log('Sync Data Response:', syncDataResponse);
    } catch (error) {
      console.error('Error syncing data:', error.message);
    }
  };

  return (
    <div style={{ margin: "20px" }}>
      <Toast ref={toast} position="top-right" />

    <Header fldNameOfTeamMember={fldNameOfTeamMember}/>
    <div style={{ margin: "1pc" }}>
      <BreadCrumb home={home} model={items}/>
      <div className="flex justify-content-center" >
         <form style={{width:"70%", border:"solid 1px #dee2e6", borderRadius:"10px"}}>
          <div style={{margin:"15px 15px 25px 15px"}}>
         <h1 style={{ fontSize: "28px" }}>{formName}</h1>

         {questions.map((question, index) => (
          <div key={question.fld_qid}>
            {renderQuestionInput(question, index)}
          </div>
        ))}
      <div style={{display: "flex", justifyContent:"center", margin:"25px 1.5% 0px 0px"}}>
        <Button className="formbutton" label="Submit" onClick={(e) => handleButtonClick(e, questions[0])}/>
      </div>
        </div>
      </form>
      </div>
    </div>
  </div>
  );
};

export default FormDetails;
