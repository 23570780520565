import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import api from '../../../api';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';


function TeamMembers({ projectName, projectId }) {
  const toast = useRef(null);

  const [visible, setVisible] = useState(false);
  const [roles, setRoles] = useState([]);
  const [teamMembers, setTeamMembers] = useState([])

  const genderOptions = [
    { label: 'Male', value: '1' },
    { label: 'Female', value: '2' },
  ];


    const getTeamMembers = async () => {
      try {
        const requestData = {
          project_id: projectId,
          project_name: projectName,
          // login_user_id : "",
          synceddatetime: "2023-08-25 14:51:50.853740",
          web_version: "1.0.1",
          db_version: "10.4.1",
          formcode: "207",
          appversion: "1.0.0",
          apikey: "kavin",
          apptypeno: "3"
        };

        const result = await api.get_TeamMembers(requestData);
        setTeamMembers(result.responsemessage);
        const teamMembersWithSlno = result.responsemessage.map((role, index) => ({ ...role, slno: index + 1 }));
        setTeamMembers(teamMembersWithSlno);
      } catch (error) {
        console.error('Error fetching Team Members:', error);
      }
    };
 useEffect(() => {
  getTeamMembers();
  }, []);

  const [globalFilter, setGlobalFilter] = useState('');

  const onFilterInputChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const filteredProducts = teamMembers.filter((teamMember) =>
    Object.values(teamMember).some((value) =>
      value.toString().toLowerCase().includes(globalFilter.toLowerCase())
    )
  );


  const deleteMembers = async (rowData) => {
  
    try {
      const payload = {
        project_id: projectId,
        project_name: projectName,
        role_id: rowData.fld_user_role,
        user_name:rowData.fld_username,
        user_id : "admin",
        synceddatetime: "2023-08-25 14:51:50.853740",
        formcode: "205",
        appversion: "1.0.0",
        apikey: "kavin",
        apptypeno: "3",
        web_version: "1.0.1",
        db_version: "10.4.1",
      };
  
      await api.deleteTeamMembers(payload);
  
      getTeamMembers();
      toast.current.show({
        severity: "success",
        summary: "Role Deleted",
        detail: "Role deleted successfully.",
      });
    } catch (error) {
      console.error("Error deleting role:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error deleting role.",
      });
    }
  };

  const confirmDelete = (rowData) => {
    confirmDialog({
      message: `Are you sure you want to delete the Team member '${rowData.fld_name_of_team_member}'?`,
      header: 'Confirm Delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteMembers(rowData),
      reject: () => {
        toast.current.show({
          severity: 'info',
          summary: 'Cancelled',
          detail: 'Team member deletion cancelled.',
        });
      },
    });
  };


    const actionTemplate = (rowData) => {
        return (
            <div >
               {/* <i className="pi pi-pencil" /> */}
               <i className="pi pi-trash ml-5" style={{color:"red"}}   onClick={() => confirmDelete(rowData)}/>
            </div>
        );
    };

  const renderHeader = () => {
    return (
        <div className="flex justify-content-between">
             <Button className='formbutton' icon="pi pi-plus-circle" ><span className='ml-2' style={{marginTop:"-2px"}}  onClick={() => setVisible(true)} >Add Team Members</span></Button>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" placeholder="Global Search" value={globalFilter} onChange={onFilterInputChange} style={{height:"42px"}}/>
            </span>
        </div>
    );
};

const header = renderHeader();

const [selectedRole, setSelectedRole] = useState({ role_id: "", role_type: "" });
const [selectedGenderId, setSelectedGenderId] = useState('');


useEffect(() => {
  const fetchData = async () => {
    try {
      const requestData = {
        project_id: projectId,
        project_name: projectName,
        login_user_id : "admin",
        synceddatetime: "2023-08-25 14:51:50.853740",
        web_version: "1.0.1",
        db_version: "10.4.1",
        formcode: "207",
        appversion: "1.0.0",
        apikey: "kavin",
        apptypeno: "3"
      };

      const result = await api.get_Roles(requestData);
      const rolesData = result.responsemessage.map(role => ({
        label: role.fld_role_name,
        value: role.fld_role_name,
        role_id: role.fld_role_id,
        role_type: role.fld_role_type,
    }));
      setRoles(rolesData);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };
  fetchData();
}, []);


const validationSchema = Yup.object({
  name_of_team_member: Yup.string().required('Member Name is required'),
  mobile_number: Yup.string().required('Phone Number required'),
  email_id: Yup.string().email('Invalid email address').required('Email is required'),
  select_gender: Yup.string().required('Gender is required'),
  role_name: Yup.string().required('Role is required'),
  new_user_name: Yup.string().required("Username is required"),
  new_user_password: Yup.string().required("Password is required"),
});

const formik = useFormik({
  initialValues: {
    name_of_team_member: '',
    mobile_number:'',
    email_id: '',
    select_gender: '',
    role_name: '',
    new_user_name: '',
    new_user_password: '',
    role_id: "",
    role_type: "",
  },
  validationSchema,
  onSubmit: async (values, { resetForm }) => {
    try {
        const selectedRole = roles.find(role => role.value === values.role_name);
        if (!selectedRole) {
          console.error("Selected role not found.");
          return;
        }
        const selectedGender = genderOptions.find(option => option.label === values.select_gender);
        if (!selectedGender) {
          console.error("Selected gender not found.");
          return;
        } 

        const additionalData = {
          login_user_id:"usr_",
          project_id: projectId,
          project_name: projectName,
          role_id: values.role_id,
          role_type: values.role_type,
          gender_id: selectedGender.value,
          synceddatetime: "2023-08-25 14:51:50.853740",
          formcode: "205",
          appversion: "1.0.0",
          apikey: "kavin",
          apptypeno: "3",
          web_version: "1.0.1",
          db_version: "10.4.1"
        };

        const response = await api.create_TeamMember(
          values.name_of_team_member,
          values.mobile_number,
          values.email_id,
          values.select_gender,
          values.role_name,
          values.new_user_name,
          values.new_user_password,
          additionalData
          );

if (response && response.status === '1') {
  toast.current.show({
    severity: "success",
    summary: "Team Member Created Successful",
    detail: response.responsemessage || "You have successfully created the Team Member.",
  });
  setVisible(false);
    resetForm();
    getTeamMembers();

} else {  
  throw new Error(response ? response.responsemessage || "An error occurred while creating the Team Member." : "No response from the server.");
}
} catch (error) {
console.error("Team Member submission error:", error);

toast.current.show({
  severity: "error",
  summary: " Team Member Failed",
  detail: error.message || "An unexpected error occurred. Please try again.",
});
}
},
});


  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <ConfirmDialog />

    <Dialog header="Create Team Member" visible={visible} style={{ width: '40vw' }} onHide={() => setVisible(false)}>
    <form onSubmit={formik.handleSubmit}>

    <Divider className="mt-0" />
      <div style={{ marginTop: "30px" }}>
      <span className="flex justify-content-center align-items-center p-float-label">
        <InputText
          id="name_of_team_member"
          name="name_of_team_member"
          style={{ width: "100%" }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name_of_team_member}
        />
       <label htmlFor="name">Team Member Name</label>
      </span>
        {formik.errors.name_of_team_member && formik.touched.name_of_team_member && (
          <div className="p-error" style={{ color: "red" }}>{formik.errors.name_of_team_member}</div>
        )}
      </div>

      <div style={{ marginTop: "30px" }}>
      <span className="flex justify-content-center align-items-center p-float-label">
        <InputText
          id="mobile_number"
          keyfilter="int"
          name="mobile_number"
          style={{ width: "100%" }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.mobile_number}
        />
        <label htmlFor="phone">Phone Number</label>
      </span>
        {formik.errors.mobile_number && formik.touched.mobile_number && (
          <div className="p-error" style={{ color: "red" }}>{formik.errors.mobile_number}</div>
        )}
      </div>

      <div style={{ marginTop: "30px" }}>
      <span className="flex justify-content-center align-items-center p-float-label">
        <InputText
          id="email_id"
          name="email_id"
          style={{ width: "100%" }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email_id}
        />
        <label htmlFor="email">Email</label>
      </span>
        {formik.errors.email_id && formik.touched.email_id && (
          <div className="p-error" style={{ color: "red" }}>{formik.errors.email_id}</div>
        )}
      </div>

      <div style={{ marginTop: "30px" }}>
      <span className="flex justify-content-center align-items-center p-float-label">
       <Dropdown
         id="select_gender"
         name="select_gender"
         style={{ height: "45px", width: "100%" }}
         value={formik.values.select_gender}
         options={genderOptions}
         onChange={(e) => {
           formik.handleChange(e);
           const selectedOption = genderOptions.find(option => option.label === e.value);
           setSelectedGenderId(selectedOption ? selectedOption.value : '');
         }}
         onBlur={formik.handleBlur}
         optionLabel="label"
         optionValue="label"
       />
        <label htmlFor="gender">Gender</label>
      </span>
        {formik.errors.select_gender && formik.touched.select_gender && (
          <div className="p-error" style={{ color: "red" }}>{formik.errors.select_gender}</div>
        )}
      </div>

      <div style={{ marginTop: "30px" }}>
      <span className="flex justify-content-center align-items-center p-float-label">
       <Dropdown
           id="role_name"
           name="role_name"
           style={{ height: '45px', width: '100%' }}
           value={formik.values.role_name}
           options={roles}
           onChange={(e) => {
               const selectedRole = roles.find(role => role.value === e.value);
               console.log("Selected Role:", selectedRole);
               setSelectedRole(selectedRole);
               formik.setFieldValue('role_name', e.value);
               if (selectedRole) {
                   formik.setFieldValue('role_id', selectedRole.role_id || '');
                   formik.setFieldValue('role_type', selectedRole.role_type || '');
               } else {
                   formik.setFieldValue('role_id', '');
                   formik.setFieldValue('role_type', '')
               }
           }}
           onBlur={formik.handleBlur}
       />
        <label htmlFor="role">Role</label>
      </span>
        {formik.errors.role_name && formik.touched.role_name && (
          <div className="p-error" style={{ color: "red" }}>{formik.errors.role_name}</div>
        )}
      </div>

      <div style={{ marginTop: "30px" }}>
      <span className="flex justify-content-center align-items-center p-float-label">
        <InputText
          id="new_user_name"
          name="new_user_name"
          style={{ width: "100%" }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.new_user_name}
        />
        <label htmlFor="username">User Name</label>
      </span>
        {formik.errors.new_user_name && formik.touched.new_user_name && (
          <div className="p-error" style={{ color: "red" }}>{formik.errors.new_user_name}</div>
        )}
      </div>

     <div style={{ marginTop: "30px" }}>
      <span className=" p-float-label">
        <Password
          id="new_user_password"
          name="new_user_password"
          toggleMask
          feedback={false}
          style={{ width: "100%" }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.new_user_password}
        />
        <label htmlFor="password">Create Password</label>
      </span>
        {formik.errors.new_user_password && formik.touched.new_user_password && (
          <div className="p-error" style={{ color: "red" }}>{formik.errors.new_user_password}</div>
        )}
      </div>

      <Divider className="mt-4" />
        <div style={{ marginTop: "20px"}} className="flex justify-content-end align-items-center">
        <Button style={{ color: "#11409E" }} label="Cancel" outlined onClick={() => setVisible(false)}/>
        <Button className="formbutton ml-3" type="submit" label="Create" />
        </div>
    </form>

    </Dialog>
      <DataTable
        value={filteredProducts}
        tableStyle={{ minWidth: '50rem' }}
        scrollHeight="350px"
        globalFilter={globalFilter} header={header}
        columnResizeMode="expand"
        resizableColumns
        paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column field="slno" header="Sl.No" sortable  />
        <Column field="role_names" header="Role Name" sortable />
        <Column field="fld_name_of_team_member" header="Members Name" sortable />
        <Column field="fld_mobile_no" header="Phone" sortable />
        <Column field="fld_email_id" header="Email" sortable />
        <Column field="fld_username" header="User Name" sortable />
        <Column field="fld_password" header="Password" sortable />
        <Column header="Action" body={actionTemplate}/>

      </DataTable>
    </div>
  );
}

export default TeamMembers;
