import React, { useEffect, useState } from 'react'
import { Chart } from 'primereact/chart';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Dashboard = () => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
      const data = {
          labels: ['Project1', 'Project2', 'Project3', 'Project4'],
          datasets: [
              {
                  label: 'Projects',
                  data: [540, 325, 702, 620],
                  backgroundColor: [
                      'rgba(178, 183, 191)',
                      'rgba(47, 49, 51)',
                      'rgba(178, 183, 191)',
                      'rgba(178, 183, 191)'
                    ],
                    borderColor: [
                      'rgba(178, 183, 191)',
                      'rgba(47, 49, 51)',
                      'rgba(178, 183, 191)',
                      'rgba(178, 183, 191)'
                    ],
                    borderWidth: 1
              }
          ]
      };
      const options = {
          scales: {
              y: {
                  beginAtZero: true
              }
          }
      };

      setChartData(data);
      setChartOptions(options);
  }, []);


  const [chartData1, setChartData1] = useState({});
  const [chartOptions1, setChartOptions1] = useState({});

  useEffect(() => {
    const data = {
      labels: ['Project1', 'Project2', 'Project3'],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: [
            'rgba(178, 183, 191)',
            'rgba(47, 49, 51)',
            'rgba(178, 183, 191)',
          ],
          hoverBackgroundColor: [
            'rgba(178, 183, 191)',
            'rgba(47, 49, 51)',
            'rgba(178, 183, 191)',
          ],
        },
      ],
    };
      const options = {
          cutout: '60%'
      };

      setChartData1(data);
      setChartOptions1(options);
  }, []);



  return (
    <div >
        <div className='flex justify-content-between align-items-center'
         style={{background:"#f8f9fa", width:"100%", height: "80px", padding: "17px", borderTop:"solid 1px #dee2e6", borderBottom:"solid 1px #dee2e6"}}>
        <Button className='formbutton' icon="pi pi-plus-circle"><span className='ml-2' style={{marginTop:"-2px"}}>Build Dashboard</span></Button>
        </div>
      {/* <div
        className="grid g-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className='col' style={{background:"#F9F9F9", borderRadius:"15px"}}>
          <div  style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <Chart type="bar" data={chartData} options={chartOptions} style={{height: "250px", width: "500px", marginTop:"0px"}}/>
          </div>

        </div>
        <div className='col' style={{background:"#F9F9F9", borderRadius:"15px", marginLeft:"20px"}}>
        <div  style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <Chart type="doughnut" data={chartData1} options={chartOptions1} style={{height: "280px", width: "280px"}}/>
          </div>
        </div>
      </div> */}

    </div>
  )
}

export default Dashboard