import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from "primereact/inputswitch";
import { Tag } from 'primereact/tag';
import { SelectButton } from 'primereact/selectbutton';
import api from '../../../api';
import { Toast } from 'primereact/toast';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';


function FormsTable({ projectName, projectId }) {
  const toast = useRef(null);

    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const options = ['Web', 'Mobile', 'Web & Mobile'];
    const [value, setValue] = useState(options[0]);
    const [forms, setForms] = useState([
        { createdBy:"Admin"}
    ])

        const getForms = async () => {
          try {
            const requestData = {
             login_user_id : "admin",
             project_id: projectId,
             project_name: projectName,
             synceddatetime: "2023-08-25 14:51:50.853740",
             web_version: "1.0.1",
             db_version: "10.4.1",
             formcode: "211",
             appversion: "1.0.0",
             apikey: "kavin",
             apptypeno: "3"
            };
    
            const result = await api.get_FormDetails(requestData);
            setForms(result.responsemessage);
            const formsWithSlno = result.responsemessage.map((role, index) => ({ ...role, slno: index + 1 }));
            setForms(formsWithSlno);
          } catch (error) {
            console.error('Error fetching Forms', error);
          }
        };
  useEffect(() => {
    getForms();
      }, []);


    const [globalFilter, setGlobalFilter] = useState('');

    const onFilterInputChange = (e) => {
        setGlobalFilter(e.target.value);
    };

    const filteredProducts = forms.filter((form) =>
    Object.values(form).some((value) =>
      value.toString().toLowerCase().includes(globalFilter.toLowerCase())
    )
  );

  const deleteForms = async (rowData) => {
  
    try {
      const payload = {
        project_id: projectId,
        project_name: projectName,
        form_id: rowData.fld_form_id,
        form_name: rowData.fld_form_name,
        form_survey_type_id: rowData.fld_form_survey_type_id,
        form_survey_type_name: rowData.fld_form_survey_type_name,
        synceddatetime: "2023-08-25 14:51:50.853740",
        formcode: "217",
        appversion: "1.0.0",
        apikey: "kavin",
        apptypeno: "3",
        web_version: "1.0.1",
        db_version: "10.4.1"
      };
  
      await api.deleteForms(payload);
  
      getForms();
      toast.current.show({
        severity: "success",
        summary: "Role Deleted",
        detail: "Role deleted successfully.",
      });
    } catch (error) {
      console.error("Error deleting role:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error deleting role.",
      });
    }
  };

  const confirmDelete = (rowData) => {
    confirmDialog({
      message: `Are you sure you want to delete the Form '${rowData.fld_form_name}'?`,
      header: 'Confirm Delete',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteForms(rowData),
      reject: () => {
        toast.current.show({
          severity: 'info',
          summary: 'Cancelled',
          detail: 'Form deletion cancelled.',
        });
      },
    });
  };

    const actionTemplate = (rowData) => {
        return (
            <div >
                <i className="pi pi-trash ml-5" style={{ color: "red" }}  onClick={() => confirmDelete(rowData)}/>
            </div>
        );
    };

    const activeTemplate = (rowData) => {
        return (
            <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
        );
    };

//     const availabilityTemplate = (rowData) => {
//       return (
//         // <SelectButton value={value} onChange={(e) => setValue(e.value)} options={options} />
//         <Button className='formbutton' label='Publish'/>
//       );
//   };

const availabilityTemplate = (rowData) => {
  const isButtonDisabled = rowData.fld_table_created === "1";
  const buttonLabel = isButtonDisabled ? "Published" : "Publish";
  const handlePublish = async () => {
    try {
      const formData = {
        project_id: projectId,
        project_name: projectName,
        form_id: rowData.fld_form_id,
        form_name: rowData.fld_form_name,
        synceddatetime: "2023-08-25 14:51:50.853740",
        web_version: "1.0.1",
        db_version: "10.4.1",
        formcode: "207",
        appversion: "1.0.0",
        apikey: "kavin",
        apptypeno: "3",
      };
  
      const response = await api.publishForm(formData);
  
      const updatedForms = forms.map((form) =>
        form.form_id === rowData.form_id ? { ...form, status: response.status } : form
      );
      setForms(updatedForms);
      getForms();
  
      if (response && response.status === 1) {
        toast.current.show({
          severity: "success",
          summary: "Published Successful",
          detail: response.responsemessage || "You have successfully created the Published.",
        });
      } else {
        throw new Error(
          response
            ? response.responsemessage || "An error occurred while creating the Published."
            : "No response from the server."
        );
      }
    } catch (error) {
      console.error("Published submission error:", error);
  
      toast.current.show({
        severity: "error",
        summary: "Published Failed",
        detail: error.message || "An unexpected error occurred. Please try again.",
      });
    }
  };
  
  
    return (
          <Button
          className={`formbutton ${isButtonDisabled ? 'disabled' : ''}`}
          label={buttonLabel}
          onClick={handlePublish}
          disabled={isButtonDisabled}
          style={{ backgroundColor: isButtonDisabled ? 'green' : '' }}
        />
      );
}
  

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button className='formbutton' icon="pi pi-plus-circle"   onClick={() => {navigate(`/createForm?projectName=${projectName}&projectId=${projectId}`)}}>
        <span className='ml-2' style={{ marginTop: "-2px" }}>Create Forms</span></Button>
        <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" placeholder="Global Search" value={globalFilter} onChange={onFilterInputChange} style={{height:"42px"}}/>
            </span>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <div>
      <ConfirmDialog />

             <Toast ref={toast} position="top-right" />
            <DataTable
                value={filteredProducts}
                tableStyle={{ minWidth: '50rem' }}
                scrollHeight="350px"
                globalFilter={globalFilter} header={header}
                columnResizeMode="expand"
                resizableColumns
                paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
                >
                <Column field="slno" header="Sl.No" sortable/>
                <Column field="fld_form_name" header="Form Name" sortable />
                <Column field="fld_system_inserted_datetime" header="Created Date"  sortable />
                <Column field="fld_form_id" header="Created By"  sortable style={{display:"none"}}/>
                <Column field="fld_form_survey_type_name" header="Survey Type"  sortable />
                <Column field="fld_form_display_type_name" header="Form Type"  sortable />
                 {/* <Column header="Active Status" body={activeTemplate}   style={{ minWidth: '170px' }}/> */}
                <Column header="Form Availability" body={availabilityTemplate} filter={false} />
                {/* <Column field="fld_table_created" header="Publish Status" /> */}
                <Column header="Action" body={actionTemplate} />
            </DataTable>
        </div>
    );
}

export default FormsTable;
